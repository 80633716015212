/* Add here you favorite fonts */
@import './components.css';

@tailwind base;

/* Start purging... */
@tailwind utilities;
/* Stop purging. */
@tailwind components;

@layer utilities {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(160, 160, 160, 0.5) transparent;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(160, 160, 160, 0.5);
    border-radius: 3px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: rgba(160, 160, 160, 0.8);
  }
}

body {
  text-rendering: geometricPrecision;
  font-feature-settings:
    'rlig' 1,
    'calt' 1,
    'ss01' 1;

  @apply bg-white text-gray-800 antialiased dark:bg-black-500 dark:text-gray-300;
}

/* fix for shifting of fixed icons due to sheets */
.root-body-container {
  overflow: auto !important;
  margin: 0 !important;
  background: theme('colors.brand.root-body-container-background');
}

.hero-image-shadow {
  box-shadow: 0 0 800px 0 var(--tw-shadow-color);
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  font-size: revert;
  font-weight: revert;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark,
  [data-theme='dark'] {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

.chatbot tr {
  border-top: 1px solid #f472b6;
}

.chatbot th,
.chatbot td {
  text-algin: center !important;
  padding: 6px 13px !important;
  border: 1px solid #f472b6;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}

.tf-v1-iframe-wrapper {
  max-height: 400px;
  max-width: 600px;
}

@media (max-width: 480px) {
  .tf-v1-popup .tf-v1-iframe-wrapper {
    min-width: 340px !important;
    min-height: 360px !important;
    width: calc(100% - 250px) !important;
    height: calc(100% - 250px) !important;
  }
}

.clamp-lines > span {
  display: block;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation-name: loading;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
}

.markdown ol {
  list-style: decimal;
}

.markdown ul {
  list-style: disc;
}

.markdown a {
  text-decoration: underline;
}

.markdown ol > li {
  counter-increment: list-number;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  min-height: 28px;
}

.markdown ol > li:before {
  --tw-translate-x: -100%;
  --tw-numeric-spacing: tabular-nums;
  --tw-text-opacity: 1;
  content: counters(list-number, '.') '.';
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
  padding-right: 0.5rem;
}

[dir='rtl'] .markdown ol > li:before {
  --tw-translate-x: 100%; /* Use 100% for RTL */
  padding-right: 0rem;
  padding-left: 0.5rem;
}

.bg-gradient {
  background: linear-gradient(112deg, #f9a8d4 -0.84%, #cef1ee 50.74%, #fef6e9 102.33%);
}

/* Auto-hide the scrollbar */
body {
  overflow: auto;
  transition: scrollbar-color 0.5s ease; /* Add a smooth transition effect */
}

.dot-animation::after {
  animation: dot-animation 1.5s infinite;
  display: inline-block;
  content: ' ';
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}

.moving-div {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #fff,
    rgba(255, 255, 255, 0)
  ); /* Adjust the fade effect */
  animation: moveLeftToRight 1.2s linear infinite;
}

.dark .moving-div {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #363636, #2d2d2d, #363636);
  animation: moveLeftToRight 1.2s linear infinite;
}

.break-anywhere {
  overflow-wrap: anywhere;
}

:root {
  --header-height: 48px; /* Default header height */
}

.fade-bottom {
  mask-position: bottom;
  mask-image: linear-gradient(to bottom, white 0%, white 80%, transparent 100%);
}

.prompt {
  @apply grid max-h-48;

  & > textarea {
    @apply max-h-48 resize-none overflow-auto tracking-normal !outline-none;
  }
}

.markdown-v2 {
  @apply prose max-w-full markdown text-base prose-sm;

  --tw-prose-pre-bg: #000000;
  --tw-prose-pre-color: #e1e1e1;

  & p {
    @apply m-0;
    display: inline;

    &:last-child {
      @apply mt-4;
      @apply mb-0;
    }

    &:first-child {
      @apply mt-0;
      @apply mb-2;
    }

    &:only-child {
      @apply mt-0 mb-0;
    }
  }

  & table {
    @apply overflow-auto border-none rounded-md shadow-sm bg-white text-sm border-separate max-h-96 text-left;
    border-collapse: collapse;
    margin: 0;

    & thead {
      @apply border-none;
      & tr {
        @apply bg-white border-none;
        & th {
          @apply text-black-700/40 px-4 py-3 max-w-32;
        }
      }
    }

    & tbody {
      & tr {
        @apply border-none leading-tight;

        &:nth-child(odd) {
          @apply bg-gray-100;
        }

        & td {
          @apply border-none p-2 text-black-700 max-w-32;
        }
      }
    }
  }

  & > ol,
  & > ul {
    gap: 16px;
  }

  & > ol {
    @apply pl-0;
  }

  & ol,
  & ul {
    @apply w-full relative my-0 flex flex-col;
    max-width: calc(100% - 1.5em);

    & li {
      @apply my-0;

      &::marker {
        @apply text-black-700;
      }
    }

    & ul li,
    & ol li {
      @apply -mx-4;
    }
  }

  pre {
    @apply p-0 m-0 rounded-none;
  }
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message_list_img {
  height: 100%;
  min-height: 300px;
  object-fit: contain;
}

.connector-disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.code-block {
  background-color: rgb(30, 30, 30);
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  font-size: 0.8571429em;
}

.copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

[data-pr='sidebar'] {
  transition: width 2s;
}

[data-radix-popper-content-wrapper] {
  > [data-pr='assistants-dropdown'],
  > [data-pr='organizations-dropdown'],
  > [data-pr='uploader-dropdown'] {
    @apply w-[316px];
  }
}

.collapsible-menu:not(.sidebar-collapsed) {
  [data-pr='chatHistoryList'],
  [data-pr='newChatButton'] {
    [data-radix-popper-content-wrapper] {
      @apply hidden;
    }
  }
}

.collapsible-menu {
  [data-pr='chatHistoryList'] {
    ol {
      @apply pr-2.5;
    }
  }

  &.sidebar-collapsed {
    @apply w-16;

    nav {
      @apply flex-col w-full gap-5;

      > div {
        @apply bg-transparent shadow-none;

        ul li:not(:first-child) {
          @apply hidden;
        }
      }
      span {
        @apply w-full bg-transparent shadow-none text-center;
      }
      ul li {
        @apply rounded-tr-lg bg-transparent;

        button {
          svg:last-of-type {
            @apply -rotate-90;
          }

          &[aria-expanded='true'] {
            svg:last-of-type {
              @apply rotate-90;
            }
          }
        }
      }
    }

    section {
      @apply w-16 h-[calc(100vh-15rem)];

      .sticky {
        @apply max-w-8 pb-2 z-10;
      }

      [data-pr='newChatButton'] {
        span {
          @apply p-1;

          &:first-of-type {
            @apply hidden;
          }
        }
      }

      [data-pr='searchButton'] {
        @apply hidden;
      }

      [data-pr='settings-list'] {
        @apply gap-0;

        a {
          &[aria-current='page'] {
            @apply bg-transparent;
          }

          div span:last-of-type {
            @apply hidden;
          }
        }
      }

      [data-pr='help-list'] {
        @apply gap-0;

        div span:last-of-type {
          @apply hidden;
        }
      }

      [data-pr='uploader'] {
        @apply w-full;

        button {
          @apply justify-center;

          span:first-of-type {
            @apply hidden;
          }
        }
      }

      [data-pr='data-storage'] {
        @apply hidden;
      }

      [data-pr='chatHistoryList'] {
        @apply gap-0;

        a span,
        h2,
        li div div button {
          @apply hidden;
        }

        li div a {
          @apply bg-transparent;
          &:hover {
            @apply bg-transparent;
          }
        }
      }
    }
  }
}
